@if (authorizedRoute) {
  <div class="single-page-main-container">
    <app-top-bar [enableSearchBar]="!enableLimitedFeatures" />
    <div class="body-container">
      @if (enableLimitedFeatures) {
        <app-settings-navbar />
      } @else {
        <app-side-bar-navigation />
      }
      <div class="body-column">
        <router-outlet />
      </div>
    </div>
  </div>
} @else {
  <router-outlet />
}
<app-tracker />
<p-toast
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
/>
