import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-properties-list-people',
  templateUrl: './people.component.html',
  styleUrl: './people.component.scss',
})
export class PeopleComponent implements OnInit {
  @Input() permissions: { email: string; fullname: string }[] = [];

  truncatedListOfPeople!: string;
  fullListOfPeople!: string;

  ngOnInit() {
    this.truncatedListOfPeople = [
      ...this.permissions.slice(0, 2),
      ...(this.permissions.length > 2
        ? [{ fullname: `+${this.permissions.length - 2}` }]
        : []),
    ]
      .map((assoc) => assoc.fullname)
      .join(', ');

    this.fullListOfPeople = this.permissions
      .map((assoc) => assoc.fullname)
      .join(', ');
  }
}
