<div class="container">
  <p-tabMenu [model]="items" class="tabs">
    <ng-template pTemplate="item" let-item>
      <ng-container *ngIf="item.route; else elseBlock">
        <a [routerLink]="item.route" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="tab-title">
            {{ item.label }}
          </span>
        </a>
      </ng-container>
      <ng-template #elseBlock>
        <a [href]="item.url" class="p-menuitem-link">
          <span [class]="item.icon"></span>
          <span class="tab-title">
            {{ item.label }}
          </span>
        </a>
      </ng-template>
    </ng-template>
  </p-tabMenu>

  <router-outlet></router-outlet>
</div>
