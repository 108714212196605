import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

type MenuItem = { title: string; icon: string; path: string | undefined };

const SETTINGS_ROOT = 'settings';

@Component({
  selector: 'app-settings-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  menuItems: MenuItem[] = [];

  constructor(private router: Router) {}

  ngOnInit() {
    this.menuItems =
      this.router.config
        .find((config) => config.path === SETTINGS_ROOT)
        ?.children?.filter((child) => child.path)
        .map((child) => {
          return {
            title: child.data?.['title'] ?? child.title,
            icon: child.data?.['icon'],
            path: `/${SETTINGS_ROOT}/${child.path}`,
          };
        }) ?? [];
  }
}
