<div class="shared-drive-container">
  <p-multiSelect
    placeholder="Select shared drive to add Overlayer to"
    [options]="sharedDrives"
    [loading]="loadingDrives"
    [(ngModel)]="selectedUserDrives"
    optionLabel="name"
    display="chip"
    showClear="true"
    class="multi-select"
  />
  <p-checkbox
    [(ngModel)]="isAutoInstallingNextDrive"
    [binary]="true"
    label="Install Overlayer automatically for shared drives created after today"
  />
  <p-splitButton
    label="Save changes"
    size="small"
    raised
    (click)="
      saveChanges.emit({
        googleDrives: selectedUserDrives,
        automaticallyWatchNewDrives: isAutoInstallingNextDrive,
      })
    "
  />
</div>
