import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MrSatanService {
  constructor(private httpClient: HttpClient) {}

  checkSheet(gsheetUrl: string) {
    const gsheetId = this.extractSpreadsheetId(gsheetUrl);

    const { href } = new URL(
      `/gsheet/${gsheetId}?preset=CUSTOM_PROPERTY`,
      environment.mrSatanURL,
    );

    return this.httpClient.get<{ error?: string; warnings?: string[] }>(href, {
      withCredentials: true,
    });
  }

  extractSpreadsheetId(ghseetUrl: string): string | null {
    const urlPattern =
      /https?:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
    const match = ghseetUrl.match(urlPattern);

    if (match && match[1]) {
      return match[1];
    }

    return null;
  }
}
