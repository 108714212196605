@if (sidePanelStatus === SidePanelStatus.LOADING) {
  <div class="loading-skeleton">
    <!-- TITLE -->
    <div class="spaced-skeleton-title">
      <p-skeleton height="24px" styleClass="mb-2" />
    </div>
    <!-- LABELS -->
    <div class="spaced-skeleton-label">
      <p-skeleton height="26px" width="33%" styleClass="mb-2" />
    </div>
    <!-- CONTRIBUTORS -->
    <div class="spaced-skeleton-contributors">
      <p-skeleton height="45px" styleClass="mb-2" />
    </div>
    <!-- DESCRIPTION -->
    <div class="spaced-skeleton-description">
      <p-skeleton height="30px" styleClass="mb-2" />
    </div>
    <!-- TAGS -->
    <div class="spaced-skeleton-tags">
      <p-skeleton height="70px" styleClass="mb-2" />
    </div>
    <!-- ACTIVITY -->
    <div class="spaced-skeleton-activity">
      <p-skeleton height="37px" styleClass="mb-2" />
    </div>
    <!-- VERSIONING -->
    <div class="spaced-skeleton-version">
      <p-skeleton height="60px" styleClass="mb-2" />
    </div>
    <!-- FOOTER -->
    <div class="spaced-skeleton-footer">
      <p-skeleton height="90px" styleClass="mb-2" />
    </div>
  </div>
}
@if (sidePanelStatus === SidePanelStatus.UNKNOWN) {
  <!-- NO DOCUMENT SELECTED -->
  <div class="centered">
    <p>No document selected</p>
  </div>
} @else if (sidePanelStatus === SidePanelStatus.MY_DRIVE) {
  <div class="my-drive">
    <img
      src="assets/icons/Overlayer-denied-icon.svg"
      class="overlayer-icon"
      alt="denied-icon"
    />
    <h1 class="my-drive-title">
      Overlayer's features are not usable on "My drive"
    </h1>
    <span class="my-drive-paragraph">
      Move this document from "My Drive" to a shared drive to use Overlayer's
      features on it.
    </span>
  </div>
} @else if (
  sidePanelStatus === SidePanelStatus.FILE_NOT_WATCHED ||
  sidePanelStatus === SidePanelStatus.OVERLAYER_NOT_ENOUGH_PERMISSION
) {
  <div class="not-watched">
    <div class="not-watched-top">
      <img
        src="assets/icons/Overlayer-warning-icon.svg"
        class="overlayer-icon"
        alt="warning icon"
      />
      <h1 class="my-drive-title">
        Overlayer's features are not installed on this drive
      </h1>
      <span class="my-drive-paragraph">
        Install Overlayer to use its features on the shared drive's files.
      </span>
    </div>
    <div class="not-watched-bottom">
      <p-button
        label="Add Overlayer to the shared drive"
        (mousedown)="onWatch.emit()"
      />
      <span class="my-drive-paragraph-smaller">
        <i class="pi pi-info-circle"></i> A technical user will be added as a
        contributor to the file's shared drive
      </span>
    </div>
  </div>
} @else if (sidePanelStatus === SidePanelStatus.NOT_FOUND) {
  <div class="not-found">
    <img
      src="assets/icons/Overlayer-denied-icons.svg"
      class="overlayer-icon"
      alt="denied-icon"
    />
    <h1 class="my-drive-title">Unable to display file details</h1>
    <span class="my-drive-paragraph">
      File hasn't been saved by Google yet.
    </span>
    <p-button
      class="refresh-button"
      icon="pi pi-refresh"
      label="Refresh side-panel"
      (mousedown)="onRefresh.emit()"
    />
  </div>
} @else if (sidePanelStatus === SidePanelStatus.SECRET_FILE) {
  <div class="secret">
    <img
      src="assets/icons/Overlayer-denied-secret-icon.svg"
      class="overlayer-icon"
      alt="denied-icon"
    />
    <h1 class="my-drive-title">
      Overlayer features are not available on "Secret" files
    </h1>
    <span class="my-drive-paragraph">
      This file is marked as "Secret". Change the file sensitivity in G-Drive to
      use Overlayer features.
    </span>
    <!-- HIDE BUTTON UNTIL SOLUTION IS FOUND FOR CONFIDENTIALITY UPDATE ON GOOGLE SIDE
    <p-button
      class="refresh-button"
      icon="pi pi-lock"
      label="Change file sensitivity"
      (mousedown)="onSensitivity.emit()"
    />-->
  </div>
}
