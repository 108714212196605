import { Injectable } from '@angular/core';
import { TrinityService } from '../trinity/trinity.service';
import { map } from 'rxjs/operators';

type Metadata =
  | { selectionOptions: { listOptions: { maxEntries: number } } }
  | { userOptions: { listOptions: { maxEntries: number } } };

export enum CustomPropertyType {
  OPTIONS_LIST = 'OPTIONS_LIST',
  PERSON = 'PERSON',
}
export type CustomPropertyApi = {
  id: string | undefined;
  name: string;
  type: CustomPropertyType;
  gsheetFileId: string | null;
  permissions: { email: string; fullname: string }[];
  labelField: { name: string; custom_metadata: Metadata };
};

type CustomPropertyInput = {
  id: string | undefined;
  name: string;
  allowMultiSelect: boolean;
  type: CustomPropertyType;
  gsheetId: string | null;
  permissions: { email: string; fullname: string }[];
};
export type CustomProperty = {
  id: string | undefined;
  name: string;
  allowMultiSelect: boolean;
  type: string;
  gsheetUrl: string | null;
  permissions: { email: string; fullname: string }[];
};

@Injectable({
  providedIn: 'root',
})
export class CustomPropertyService {
  private readonly endpoint: string = '/custom-property';

  constructor(private trinityService: TrinityService) {}

  getCustomProperties() {
    return this.trinityService
      .get<{ customProperties: CustomPropertyApi[] }>(this.endpoint, {
        authorized: true,
      })
      .pipe(
        map((response) =>
          response.customProperties.map((customProperty) => {
            return {
              id: customProperty.id,
              name: customProperty.labelField.name,
              type: customProperty.gsheetFileId
                ? 'Options list'
                : 'Person (person)',
              gsheetUrl: customProperty.gsheetFileId
                ? `https://docs.google.com/spreadsheets/d/${customProperty.gsheetFileId}`
                : '',
              allowMultiSelect: this.isMultiSelect(
                customProperty.labelField.custom_metadata,
              ),
              permissions: customProperty.permissions,
            };
          }),
        ),
      );
  }

  getCustomProperty(id: string) {
    return this.trinityService
      .get<{ customProperty: CustomPropertyApi }>(`${this.endpoint}/${id}`, {
        authorized: true,
      })
      .pipe(
        map((response) => ({
          id: response.customProperty.id,
          name: response.customProperty.labelField.name,
          type: response.customProperty.gsheetFileId
            ? CustomPropertyType.OPTIONS_LIST
            : CustomPropertyType.PERSON,
          gsheetUrl: response.customProperty.gsheetFileId
            ? `https://docs.google.com/spreadsheets/d/${response.customProperty.gsheetFileId}`
            : '',
          allowMultiSelect: this.isMultiSelect(
            response.customProperty.labelField.custom_metadata,
          ),
          permissions: response.customProperty.permissions,
        })),
      );
  }

  createCustomProperty(data: CustomPropertyInput) {
    return this.trinityService.post<CustomPropertyApi>(this.endpoint, {
      body: data,
      authorized: true,
    });
  }

  updateCustomProperty(id: string, data: CustomPropertyInput) {
    return this.trinityService.put<CustomPropertyApi>(
      `${this.endpoint}/${id}`,
      {
        body: data,
        authorized: true,
      },
    );
  }

  deleteCustomProperty(id: string) {
    return this.trinityService.delete<CustomPropertyApi>(
      `${this.endpoint}/${id}`,
      {
        authorized: true,
      },
    );
  }

  private isMultiSelect(metadata: Metadata) {
    if ('selectionOptions' in metadata) {
      return metadata.selectionOptions.listOptions.maxEntries > 1;
    }

    return metadata.userOptions.listOptions.maxEntries > 1;
  }
}
