import { People, Domain, PrimeFileNode, User } from 'app/types';

export interface GoogleDrive {
  /** The ID of the drive. */
  id: string;

  /** The color of the drive. */
  color: string;

  /** The drive's hidden state. */
  hidden: boolean;

  /** The name of the drive. */
  name: string;
}

export interface AuthorizedGoogleDrive extends GoogleDrive {
  /** Is the current user authorized to scan the drive ? */
  authorized: boolean;

  /** Is the drive scanned ? */
  scanned: boolean;

  /** Is the drive subscribed ? */
  subscribed: boolean;

  /** The drive is under scan ?. */
  scanning: boolean;

  /** If the drive is watched or not by the GED */
  is_watched: boolean;
}
export interface GetUserDrivesResponse {
  /** The drives that the user has access to. */
  drives: GoogleDrive[];

  /** The next page token. */
  next_page_token?: string;
}

export interface GetUserAuthorizedDrivesResponse {
  /** The drives that the user has access to. */
  drives: AuthorizedGoogleDrive[];

  /** The next page token. */
  next_page_token?: string;
}

export interface GetDriveFilesResponse {
  /** The files in the drive. */
  files: PrimeFileNode[];

  /** The next page token. */
  next_page_token?: string;
}

export enum FileFlagType {
  VERIFIED = 'status_verified',
  POPULAR = 'popular',
  FLAGGED = 'flagged',
}

export interface DriveEntity {
  /** The ID of the drive entity. */
  id: string;

  /** The name of the drive entity. */
  name: string;

  /** The drive entity's attached domain. */
  domain?: Domain;

  /** The drive entity's scan date. */
  last_scanned_at: Date;

  /** The drive entity's creation date. */
  created_at: Date;

  /** The drive entity's last update date. */
  updated_at: Date;
}

export interface GetUserDrivesOptions {
  /** The max number of drives to query per request. */
  limit: number;

  /** The next page token. */
  next_page_token?: string;

  /** Whether to include domain wide drives. */
  include_domain_wide?: boolean;
}

export interface DriveEntityResponse {
  /** The drive entity. */
  drives: DriveEntity[];
}

export interface SearchFilesOptions {
  /** The search query. */
  search: string;

  /** The drive id to search in. */
  driveid?: string;

  /** The max number of files to query per request. */
  limit?: number;

  /** The next page token. */
  next_page_token?: string;

  /**
   * A comma-separated list of sort keys. Valid keys are 'createdTime', 'folder', 'modifiedByMeTime', 'modifiedTime', 'name', 'name_natural', 'quotaBytesUsed', 'recency', 'sharedWithMeTime', 'starred', and 'viewedByMeTime'. Each key sorts ascending by default, but can be reversed with the 'desc' modifier. Example usage: ?orderBy=folder,modifiedTime desc,name.
   */
  orderBy?: string;
}

export interface ContributionsResponse {
  /** The contributions. */
  contributions: Contribution[];

  /** The next page token. */
  next_page_token?: string;
}

export interface Contribution {
  /** People informations */
  people: People;

  /** Number of contributions of the user */
  count: number;
}

export interface SubscribedDrive {
  /** The ID of the subscribed drive. */
  id: string;

  /** The name of the subscribed drive. */
  name: string;

  /** If the subscribed drive is watched. */
  is_watched: boolean;

  /** The domain of the subscribed drive. */
  domain: Domain;

  /** The last scanned date of the subscribed drive. */
  last_scanned_at: Date;

  /** The last user who scanned the drive. */
  last_scan_by: User;

  /** The creation date of the subscribed drive. */
  created_at: Date;

  /** The last update date of the subscribed drive. */
  updated_at: Date;
}

export interface UnprocessedDrive {
  /** The ID of the drive. */
  id: string;

  /** The reason it's failed to watch */
  reason: number;
}

export interface WatchDriveResponse {
  /** The subscribed drives that succeeded to watch. */
  processed: SubscribedDrive[];

  /** The drives that failed to watch. */
  failed: UnprocessedDrive[];
}
