import { Component, OnInit } from '@angular/core';
import {
  CustomProperty,
  CustomPropertyService,
} from 'app/services/custom-property/custom-property.service';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'app-custom-properties-list',
  templateUrl: './list.component.html',
  styleUrl: './list.component.scss',
})
export class ListCustomPropertiesComponent implements OnInit {
  customProperties: CustomProperty[] = [];
  lineMenuItems!: Record<string, MenuItem[]>;
  loading: boolean = true;

  constructor(
    private customPropertiesService: CustomPropertyService,
    private confirmationService: ConfirmationService,
  ) {}

  ngOnInit() {
    this.customPropertiesService
      .getCustomProperties()
      .subscribe((customProperties) => {
        this.customProperties = customProperties;
        this.loading = false;

        // We need to generate one menu item for each custom property
        // so that the command gets the correct context
        this.lineMenuItems = customProperties.reduce(
          (map, prop) => {
            map[prop.id!] = [
              {
                label: 'Edit',
                icon: 'pi pi-pencil',
                routerLink: `../edit/${prop.id}`,
              },
              {
                label: 'Delete',
                icon: 'pi pi-trash',
                command: () => {
                  const customProperty: CustomProperty | undefined =
                    this.customProperties.find((prop) => prop.id === prop.id);
                  if (customProperty) {
                    this.deleteCustomProperty(prop);
                  }
                },
              },
            ];
            return map;
          },
          {} as Record<string, MenuItem[]>,
        );
      });
  }

  deleteCustomProperty(customProperty: CustomProperty) {
    this.confirmationService.confirm({
      message:
        'This property will be removed from all files previously applied and removed from search shortly thereafter. This action cannot be undone.',
      header: `Delete "${customProperty.name}" property?`,
      acceptIcon: 'none',
      acceptLabel: 'Delete permanently',
      acceptButtonStyleClass: 'p-button-danger',
      rejectIcon: 'none',
      rejectLabel: 'Cancel',
      rejectButtonStyleClass: 'p-button-secondary p-button-outlined',
      accept: () => {
        return this.customPropertiesService
          .deleteCustomProperty(customProperty.id!)
          .subscribe(() => {
            this.customProperties = this.customProperties.filter(
              (prop) => prop.id !== customProperty.id,
            );
          });
      },
      reject: () => {},
    });
  }
}
