<div>
  @if (hasRights) {
    <router-outlet></router-outlet>
  } @else {
    <div class="admin-container">
      <div class="admin-texts">
        <h1>Admin Login</h1>
        <h2>
          To be able to access "Settings" pages, you need to be logged as an
          admin.
        </h2>
      </div>
      <p-button
        label="Login as an admin"
        size="small"
        outlined="true"
        (click)="loginAsAdmin()"
      ></p-button>
    </div>
  }
</div>
