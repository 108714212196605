<p-tabView>
  @for (tab of tabs; track tab) {
    <p-tabPanel>
      <ng-template pTemplate="header">
        <i class="pi {{ tab.header.icon }} side-bar-todo-icon"></i>
        <span class="normal-weight">{{ tab.header.text }}</span>
      </ng-template>
      @for (section of tab.sections; track section) {
        <ng-container *ngComponentOutlet="section" />
      }
    </p-tabPanel>
  }
</p-tabView>
