<nav class="side-bar" aria-label="sub-navigation">
  <ul>
    @for (item of menuItems; track item) {
      <li
        class="side-bar-button"
        [routerLink]="item.path"
        routerLinkActive="active"
      >
        <i class="pi side-bar-todo-icon {{ item.icon }}"></i>
        <span>{{ item.title }}</span>
      </li>
    }
  </ul>
</nav>
