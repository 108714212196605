import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  FileLabel,
  FunctionEmitter,
  LabelFieldOptionType,
  Workflow,
  WorkflowState,
} from 'app/types';
import { hasAnyOfThisLabels } from 'app/utils/utils';

@Component({
  selector: 'side-panel-activity',
  templateUrl: './side-panel-activity.component.html',
  styleUrl: './side-panel-activity.component.scss',
})
export class SidePanelActivityComponent {
  @Input() workflows?: Workflow[] = undefined;
  @Input() labels?: FileLabel[] = [];
  @Input() isUserPendingApprover: boolean = false;
  @Input() loading: boolean = false;
  @Output() startWorkflowEvent: EventEmitter<FunctionEmitter> =
    new EventEmitter();
  @Output() approveWorkflowEvent: EventEmitter<FunctionEmitter> =
    new EventEmitter();
  @Output() rejectWorkflowEvent: EventEmitter<FunctionEmitter> =
    new EventEmitter();
  @Output() exportPdfEvent: EventEmitter<FunctionEmitter> = new EventEmitter();

  readonly Workflow = WorkflowState;
  readonly minimalSizeForHistory = 1;
  readonly workflowStatesMap = {
    [WorkflowState.VALIDATED]: {
      icon: 'pi pi-check',
      value: '',
      class: 'workflow-approved',
    },
    [WorkflowState.NONE]: {
      icon: '',
      value: 'No workflow started',
      class: '',
    },
    [WorkflowState.WAITING]: {
      icon: 'pi pi-clock',
      value: 'Pending approval',
      class: 'workflow-pending',
    },
    [WorkflowState.REJECTED]: {
      icon: 'pi pi-times',
      value: 'Rejected',
      class: 'workflow-rejected',
    },
  };

  currentWorkFlow?: Workflow = undefined;

  ngOnChanges() {
    if (this.workflows && this.workflows.length > 0) {
      this.currentWorkFlow = this.workflows[0];
    }
  }

  stringToWorkflowState(state: string): WorkflowState {
    return state as WorkflowState;
  }

  capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  canRenewVerification(): boolean {
    if (!this.labels) return false;
    return (
      hasAnyOfThisLabels(this.labels, [
        LabelFieldOptionType.STATUS_VERIFICATION_EXPIRES_SOON,
        LabelFieldOptionType.STATUS_VERIFIED_EXPIRED,
        LabelFieldOptionType.STATUS_HAS_BEEN_EDITED,
      ]) && this.currentWorkFlow?.state === this.Workflow.VALIDATED
    );
  }
}
