import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function gsheetUrlValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const urlPattern =
      /https?:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
    const url = control.value;

    if (url && !urlPattern.test(url)) {
      return { error: 'This is not a valid G-Sheet url.' };
    }

    return null;
  };
}
