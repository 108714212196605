export const environment = {
  production: true,
  apiURL: 'https://trinity-preprod.int.overlayer.ai/',
  pyroneURL: 'https://pyrone.int.overlayer.ai',
  mrSatanURL: 'https://mrsatan.int.overlayer.ai/',
  googleTagManagerID: '',
  heapID: '',
  smartlookID: '',
  crispID: '',
};
