import { Component, OnInit } from '@angular/core';
import {
  LocalStorageService,
  USER_INFO_KEY,
  AUTH_INFO,
} from 'app/services/local-storage/local-storage.service';
import { Role, User } from 'app/types';
import { AuthService } from 'app/services/auth/auth.service';
import { LoginURIParams } from 'app/types';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  hasRights: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {}

  async ngOnInit() {
    const roles = this.localStorageService.getItem<User>(USER_INFO_KEY)?.roles;

    if (roles?.includes(Role.GOOGLE_DOMAIN_ADMIN)) {
      this.hasRights = true;
      return;
    }
  }

  async loginAsAdmin() {
    const redirectUri = new URL(this.authService.redirectUri);

    redirectUri.searchParams.set(
      LoginURIParams.REDIRECT,
      LoginURIParams.SETTINGS,
    );
    redirectUri.searchParams.set(LoginURIParams.ROLE, Role.GOOGLE_DOMAIN_ADMIN);
    redirectUri.searchParams.set(LoginURIParams.AUTO_LOGIN, 'true');

    this.localStorageService.deleteItem(AUTH_INFO);
    this.localStorageService.deleteItem(USER_INFO_KEY);
    // Change the URL to the login page with the redirect and role parameters
    window.location.href = redirectUri.href;
  }
}
