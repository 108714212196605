import { Component, Input, Type } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ComponentType = Type<any> | null;

export type TabConfig = {
  header: { text: string; icon: string };
  sections: ComponentType[];
};

@Component({
  selector: 'app-tabs-with-sections',
  templateUrl: './tabs-with-sections.component.html',
  styleUrl: './tabs-with-sections.component.scss',
})
export class TabsWithSectionsComponent {
  @Input() tabs: TabConfig[] = [];
}
