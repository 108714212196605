<h1 class="{{ wording.name }}-title">
  {{ wording.title }}
</h1>
<div class="{{ wording.name }}-input">
  <label for="{{ wording.name }}" class="apply-attribute-row">
    {{
      client?.service_configuration?.custom_drive_watch
        ? wording.selectedLabel
        : wording.label
    }}
  </label>
  <p-inputSwitch
    inputId="{{ wording.name }}"
    [disabled]="!hasRights"
    [ngModel]="client?.service_configuration?.custom_drive_watch"
    readonly
    (click)="toggleDisplaySwitch()"
  />
</div>
@if (client?.service_configuration?.custom_drive_watch) {
  <div class="shared-drive-selector">
    <app-shared-drive-selector
      [sharedDrives]="userDrives"
      [loadingDrives]="loadingDrives"
      [client]="client"
      (saveChanges)="saveSelectedDrives($event)"
    />
  </div>
}
<p-dialog
  header="Desactivate custom installation?"
  [modal]="true"
  [(visible)]="displayConfirmationDialog"
  [style]="{ width: '40%' }"
>
  <div class="dialog-container">
    <div>
      By doing so, Overlayer will be installed on all your shared drives. You
      will be able to activate the custom installation again at any moment in
      the future.
    </div>
    <div class="dialog-buttons-container">
      <p-button
        size="small"
        label="Cancel"
        severity="secondary"
        (click)="displayConfirmationDialog = false"
      />
      <p-button
        size="small"
        label="Install Overlayer on all Shared drives"
        (click)="desactivateCustomInstallation()"
      />
    </div>
  </div>
</p-dialog>
