import { User, File } from 'app/types';

export enum WorkflowType {
  FLAG = 'flag',
  VERIFY = 'verify',
  CAMPAIGN = 'campaign',
}

export enum WorkflowState {
  WAITING = 'waiting',
  VALIDATED = 'validated',
  REJECTED = 'rejected',
  NONE = 'none',
}

export enum WorkflowUserRole {
  APPROVER = 'approver',
  REJECTER = 'rejecter',
  PENDING_VERIFIER = 'pending_verifier',
  WORKFLOW_CREATOR = 'workflow_creator',
  CAMPAIGN_TARGET = 'campaign_target',
}

export interface Workflow {
  /** The ID of the workflow. */
  id: number;

  /** The workflow associated file */
  file: File;

  /** The user who requested the workflow */
  requested_by: User;

  /** Workflow related users */
  related_users: RelatedUser[];

  /** The workflow type */
  type: WorkflowType;

  /** The workflow state */
  state: WorkflowState;

  /** Workflow pdf file */
  has_exported_pdf: boolean;

  /** The workflow created date */
  created_at: Date;

  /** The workflow updated date */
  updated_at: Date;
}

export interface WorkflowsResponse {
  workflows: Workflow[];
  next_page_token: string | null;
}

export interface WorkflowResponse {
  /** The workflow. */
  workflow: Workflow;
  /** Whether file has been indexed to AI or not **/
  indexed: boolean;
}

export interface RelatedUser {
  /** Related user's ID */
  id: number;

  /** Related user information */
  user: User;

  /** Related user's role */
  role: WorkflowUserRole;

  /** Related user's created date */
  created_at: Date;

  /** Related user's updated date */
  updated_at: Date;
}
