import { Component } from '@angular/core';
import type { TabConfig } from 'app/components/tabs-with-sections/tabs-with-sections.component';
import { CustomSharedDrivesComponent } from 'app/pages/settings/custom-shared-drives/custom-shared-drives.component';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrl: './installation.component.scss',
})
export class InstallationComponent {
  tabs: TabConfig[] = [
    {
      header: {
        text: 'Installation settings',
        icon: 'pi-sliders-h',
      },
      sections: [CustomSharedDrivesComponent],
    },
  ];
}
