import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { gsheetUrlValidator } from './directives/gsheet-url.directive';
import { GsheetValueValidator } from './directives/gsheet-value.directive';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import {
  CustomPropertyService,
  CustomPropertyType,
} from 'app/services/custom-property/custom-property.service';
import { MrSatanService } from 'app/services/mrsatan/mrsatan.service';
import { PeopleService } from 'app/services/people/people.service';
import { People } from 'app/types';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-custom-properties-create',
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss',
})
export class CreateCustomPropertyComponent implements OnInit {
  customPropertyId: string | undefined;
  submitting: boolean = false;
  submitError: string | null = null;

  customPropertyForm = this.formBuilder.group({
    name: ['', Validators.required],
    type: ['person'],
    gsheetUrl: [
      '',
      {
        disabled: true,
        updateOn: 'blur',
        validators: [gsheetUrlValidator()],
        asyncValidators: [
          this.gsheetValueValidator.validate.bind(this.gsheetValueValidator),
        ],
      },
    ],
    allowMultiSelect: [false, Validators.required],
    permissions: [[] as { email: string; fullname: string }[]],
  });

  activeStepIndex: number = 0;
  directorySuggestions: People[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private gsheetValueValidator: GsheetValueValidator,
    private customPropertyService: CustomPropertyService,
    private mrsatanService: MrSatanService,
    private peopleService: PeopleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    // If we havecustomPropertyId, it's an edition. Load the custom property data into the form
    this.activatedRoute.params.subscribe((params) => {
      if (params['id']) {
        this.customPropertyId = params['id'];

        // Those fields are not editable
        this.customPropertyForm.get('type')?.disable();
        this.customPropertyForm.get('gsheetUrl')?.disable();

        this.customPropertyService
          .getCustomProperty(this.customPropertyId!)
          .subscribe((customProperty) => {
            this.customPropertyForm.setValue({
              allowMultiSelect: customProperty.allowMultiSelect,
              gsheetUrl: customProperty.gsheetUrl,
              name: customProperty.name,
              type: customProperty.type,
              permissions: customProperty.permissions,
            });
          });
      }
    });

    // Preload directory suggestions
    this.getDirectorySuggestions();

    // When we select the type 'person', we need to reset the gsheetUrl field
    this.customPropertyForm.get('type')?.valueChanges.subscribe((value) => {
      if (value === 'person') {
        this.customPropertyForm.get('gsheetUrl')?.setValue('');
      }
    });
  }

  onSubmit() {
    this.submitError = null;

    // If the form is invalid, mark all fields as dirty to show the errors
    // The form remains valid if the gsheetUrl has warnings only
    if (!this.isFormValidIgnoringGsheetWarnings()) {
      Object.keys(this.customPropertyForm.controls).forEach((key) => {
        this.customPropertyForm.get(key)?.markAsDirty();
      });
      this.submitError = 'Please fill in all required fields';
      return;
    }

    this.submitting = true;
    const { gsheetUrl, name, type, permissions, allowMultiSelect } =
      this.customPropertyForm.getRawValue();

    const data = {
      id: this.customPropertyId,
      name: name ?? '',
      type: type as CustomPropertyType,
      gsheetId: gsheetUrl
        ? this.mrsatanService.extractSpreadsheetId(gsheetUrl)
        : null,
      allowMultiSelect: allowMultiSelect ?? false,
      permissions: permissions ?? [],
    };

    if (data.id) {
      this.customPropertyService.updateCustomProperty(data.id, data).subscribe({
        complete: () => {
          this.customPropertyForm.reset();
          this.router.navigate(['../../list'], {
            relativeTo: this.activatedRoute,
          });
        },
        error: (err) => {
          this.submitError =
            err.error.message?.message ??
            'Error while updating the custom property';
          this.submitting = false;
        },
      });
    } else {
      this.customPropertyService.createCustomProperty(data).subscribe({
        complete: () => {
          this.customPropertyForm.reset();
          this.router.navigate(['../list'], {
            relativeTo: this.activatedRoute,
          });
        },
        error: (err) => {
          this.submitError =
            err.error.message?.message ??
            'Error while creating the custom property';
          this.submitting = false;
        },
      });
    }
  }

  private isFormValidIgnoringGsheetWarnings(): boolean {
    const gsheetUrlControl = this.customPropertyForm.get('gsheetUrl');
    if (this.customPropertyForm.valid) {
      return true;
    }

    const allFieldsExceptGsheetAreValid = Object.keys(
      this.customPropertyForm.controls,
    ).every((controlName) => {
      if (controlName === 'gsheetUrl') {
        return true;
      }

      return this.customPropertyForm.get(controlName)?.valid;
    });

    if (
      allFieldsExceptGsheetAreValid &&
      gsheetUrlControl?.errors &&
      gsheetUrlControl.errors['warnings'] &&
      !gsheetUrlControl.errors['error']
    ) {
      return true;
    }

    return false;
  }

  goToPreviousStep(fromIndex: number) {
    this.activeStepIndex = Math.max(fromIndex - 1, 0);
  }

  goToNextStep(fromIndex: number) {
    this.activeStepIndex = Math.min(fromIndex + 1, 2);
  }

  getDirectorySuggestions(event?: AutoCompleteCompleteEvent) {
    this.peopleService.list(event?.query).subscribe((people) => {
      this.directorySuggestions = [...people.users, ...people.groups];
    });
  }

  protected readonly CustomPropertyType = CustomPropertyType;
}
