import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-custom-properties',
  templateUrl: './custom-properties.component.html',
  styleUrl: './custom-properties.component.scss',
})
export class CustomPropertiesComponent implements OnInit {
  items!: MenuItem[];

  ngOnInit() {
    this.items = [
      {
        label: 'Custom properties',
        icon: 'pi pi-tags',
        route: './list',
        routerLink: 'list',
      },
      {
        label: 'Create a custom property',
        icon: 'pi pi-plus',
        route: './create',
      },
    ];
  }
}
