import { Domain, People } from 'app/types';

export enum Role {
  /* Default domain user role (delegated from Google scopes) */
  GOOGLE_DOMAIN_USER = 'google_domain_user',

  /* Dedicated user account in client domain */
  GOOGLE_DOMAIN_DEDICATED_USER = 'google_domain_dedicated_user',

  /* Domain operator (can do multiple non-google actions) */
  DOMAIN_OPERATOR = 'domain_operator',

  /* Google domain admin role (used for privileged actions and delegated from Google scopes) */
  GOOGLE_DOMAIN_ADMIN = 'google_domain_admin',

  /* Overlayer super admin role */
  OVERLAYER_SUPER_USER = 'overlayer_super_user',

  /* Internal api tokens (pyrone ...) */
  INTERNAL_API = 'internal_api',
}

export interface User {
  id: string;
  primary_email: string;
  emails: string[];
  people: People;
  roles: Role[];
  domain: Domain;
  created_at: Date;
  updated_at: Date;
}
